import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';

export const testingQueryKeys = {
  base: ['testing'] as const,
  isAvailable: () => [...testingQueryKeys.base, 'isAvailable'] as const,
  listTestUsers: () => [...testingQueryKeys.base, 'testUsers'] as const,
};

export const isTestingAvailableQuery = queryOptions({
  queryFn: async () => {
    try {
      return (await crownApi.Testing_isAvailable()).data
    } catch(error) {
      console.error(error);
      return { available: false };
    }
  },
  queryKey: testingQueryKeys.isAvailable()
});

export const listTestUsersQuery = queryOptions({
  queryFn: async () => (await crownApi.Testing_listTestUsers()).data,
  queryKey: testingQueryKeys.listTestUsers()
});
