import { ReactNode, useState } from 'react';

import { UserProfileDropdown } from '@/components/profile-dropdown/UserProfileDropdown.tsx';
import AppBar from '@/components/ui/AppBar.tsx';
import MobileSidebarSurface from '@/components/ui/layout/dashboard-layout/MobileSidebarSurface.tsx';
import { SidebarContext } from '@/components/ui/layout/dashboard-layout/sidebar/SidebarContext.ts';
import { RouterLoadingIndicator } from '@/components/ui/RouterLoadingIndicator.tsx';

type DashboardContentLayoutProps = {
  content: ReactNode;
  sidebar: ReactNode;
};

const DashboardContentLayout = ({
  content,
  sidebar,
}: DashboardContentLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <SidebarContext.Provider
        value={{ closeSidebar: () => setSidebarOpen(false) }}
      >
        <AppBar
          endSlot={<UserProfileDropdown className={'hidden sm:inline'} />}
          onHamburgerClick={() => setSidebarOpen(true)}
        />
        <div className={'w-full h-app-bar'} />

        <div className="flex w-full min-h-[calc(100vh_-_var(--app-bar-height))]">
          <div className="hidden lg:block w-[var(--sidebar-width)] bg-white shadow-md h-[calc(100vh_-_var(--app-bar-height))] sticky top-[var(--app-bar-height)] left-0 overflow-y-auto">
            {sidebar}
          </div>

          <div className="flex-1 p-4 sm:p-10 flex items-center flex-col md:pt-14 relative">
            {content}
          </div>
        </div>

        <MobileSidebarSurface
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
        >
          {sidebar}
        </MobileSidebarSurface>
        <RouterLoadingIndicator />
      </SidebarContext.Provider>
    </div>
  );
};

export default DashboardContentLayout;
