import { createFileRoute } from '@tanstack/react-router'
import { TestingUsers } from '@/testing/TestingUsers.tsx';
import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { listTestUsersQuery } from '@/testing/api.ts';

export const Route = createFileRoute('/testing/users')({
  component: TestingUsers,
  meta: () => ([{title: 'Users'}]),
  loader: () => crownQueryClient.ensureQueryData(listTestUsersQuery),
})
