import { createFileRoute, redirect } from '@tanstack/react-router';
import { toast } from 'react-hot-toast';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { isTestingAvailableQuery } from '@/testing/api.ts';
import { Testing } from '@/testing/Testing.tsx';

export const Route = createFileRoute('/testing')({
  component: Testing,
  meta: () => [{ title: 'QA Tools' }],
  beforeLoad: async () => {
    const { available } = await crownQueryClient.ensureQueryData(
      isTestingAvailableQuery,
    );

    if (!available) {
      toast.error('Page is not available in this deployment');
      throw redirect({ to: '/' });
    }
  },
});
