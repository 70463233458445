import { Wrench } from '@phosphor-icons/react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { DashboardContentSurface } from '@/components/ui/layout/dashboard-layout/DashboardContentSurface.tsx';
import DashboardHeader from '@/components/ui/layout/dashboard-layout/DashboardHeader.tsx';
import { listTestUsersQuery } from '@/testing/api.ts';
import { TestingUserRow } from '@/testing/user/TestingUserRow.tsx';

export const TestingUsers = () => {
  const {
    data: { users },
  } = useSuspenseQuery(listTestUsersQuery);


  return (
    <>
      <DashboardHeader>
        <Wrench className={'w-8 h-8 mr-2'} />
        User Tools
      </DashboardHeader>

      <DashboardContentSurface header={'Users'} className={'p-0'}>
        <div className={'divide-y divide-muted'}>
          {users.map((user) => (
            <TestingUserRow user={user} key={user.id} />
          ))}
        </div>
      </DashboardContentSurface>
    </>
  );
};
